h5 {
    font-size: 18px;
    font-weight: 600;
}
.list-style-type-none {
    list-style-type: none;
    padding: 0!important;
}
a {
    color: inherit;
}
.footer {
    padding-top: 3rem;
    background-color: #13002e;
}